export const bookNodes = [
  {
    id: 0,
    name: "הכושר ההוליסטי",
    shortDesc:
      "הכושר ההוליסטי מציג בפני הקורא גישה חדשנית וייחודית לפיתוח כושר גופני, השמה דגש על צרכיו השונים של המתאמן, לבד מאלו הפיזיים: צרכים רגשיים, רוחניים, שכליים, חברתיים ועוד. זאת לאור ההנחה כי לא די לשלוט בכל מרכיבי הכושר, בעקרונות האימון ובשיטותיו, אלא חשוב להכיר את מגוון הגורמים הפנימיים להנעה ולהתמדה בפעילות גופנית...",
    coverSrc: require("../assets/client-images/Books/Front-Covers/holistic-fitness.webp"),
    fullCover: require("../assets/client-images/Books/Full-Covers/Holistic-fitness.png"),
    purchaseMethod: "contact",
    purchaseMessage:
    '<h3>לרכישה צרו קשר <br /> </h3> <p style="margin-top= 0rem; margin-bottom= 0.5rem; font-size=1.3rem; font-weight=400">yigalpinchas@gmail.com</p>',
    language: "he",
    paragraphs: [
      "<h4>כושר הוליסטי® – רקע ומבוא</h4>",
      "<p>לאחר עשרות שנים של עיסוק בחינוך גופני ובהוראת חינוך גופני בקרב אלפי מתאמנים ותלמידים במערכות הפורמליות והבלתי פורמליות, הבנתי כי כדי לקצור הצלחות דרושה גישה שונה מאלה הרווחות כיום בתחום. במהלך הוראת הפעילות הגופנית אם בחדרי הכושר, אם בחוגים, אם בבתי־ספר, בתיכונים ובמכללות – נוכחתי לדעת כי הגישות הקיימות אינן נותנות תשובה מספקת למגוון האוכלוסיות. עובדה ידועה היא, שלמרות יתרונותיו הגלויים והמובהקים של האימון הגופני, הרי שמרבית בני האדם עדיין נמנעים מפעילות כושר, ואלה הנוטלים בה חלק מתקשים לממש ולקיים פעילותם בהתמדה.</p>",
      "<p>עם השנים פיתחתי גישה חדשה, אשר נועדה לתת מענה לכלל המתאמנים, ובמיוחד לאותם אלפים, שעד עתה נשרו מתוכניות כושר, לא התמידו בהן או נמנעו מהן לגמרי. גישה זו, על כוחותיה ועל חוזקותיה, נוסדה במיוחד כדי לענות על צרכי המתאמן בשלל היבטים: פיזיים,רגשיים, רוחניים, שכליים.</p>",
      "<p>גישת הכושר ההוליסטי® מיוסדת על הפילוסופיה ההוליסטית, לפיה האדם הוא מכלול שלם, מערכת 'אורגנית' אחת שרכיביה גוף, נפש, רוח ושכל. כאשר רכיב מסוים משתנה, או שמשתנה תפקודו, אזי גם יתר הרכיבים במערכת יושפעו וישתנו בהתאם, שכן בין חלקי המכלול קיים יחס של גומלין וקשר הדדי מובהק. בהינתן שכך, הרי כדי לחולל שינוי פיזי בקרב מתאמן, עלינו להתבונן בכל פניו, כולל הנפשיים, ההתנהגותיים, החברתיים וכד'. עוד עלינו לזהות את מערכות היחסים בין שלל הפנים השונים הקשורים זה בזה ומשפיעים זה על זה.</p>",
      "<p>כאשר אנו עוסקים במונח 'כושר' כוונתנו ליכולת, כלומר לכשירות לבצע פעולה. שם העצם הזכרי כֹּשֶׁר שאול מתכונתו של הַכָּשֵׁר, זה שמצבו טוב. בעולם ההלכה הכַשרוּת היא תַקִּינות. המילה כוללת גם משמעות של יכולת ואפשרות, כגון בצירופים: כושר עבודה, כושר ניתוח. כך אפוא כושר גופני.</p>",
      "<p>מכאן ש'הכושר הגופני' במובנו הרחב אינו אלא אחד ממכלול הכשרים של האדם לצד כשרים שכליים, רגשיים, חברתיים ועוד. בהתייחס לכך 'כושר גופני' הוא זה המאפשר לאדם להפעיל את כלל מערכות גופו, בכל טווח הפעילות התפקודית, לשם הבטחת קיומו וסיפוק צרכיו. במסגרת הגדרה זו נכללות מערכות הגוף השונות: עצבים, נשימה, לב וכלי־דם, תנועה, עיכול, שתן, מין והמערכת החיסונית. אלא שהצירוף 'כושר גופני' מתייחס רק למערכת ה תנועה בדרגת מאמץ משמעותי.</p>",
      "<p>בעוד 'הכושר הגופני' מתייחס לגוף לבדו, ובתוך כך למערכת התנועה לבדה בדרגת מאמץ משמעותי, הרי שגישת 'הכושר ההוליסטי' תתייחס לכל אחד מן הממדים באדם: גופני, נפשי, רוחני וקוגניטיבי בִּכלל דרגות המאמץ. זוהי אפוא תפישה הדוגלת בטיפוח אורח חיים בריא ופעיל המבוססת על פעילות גופנית השואפת להרמוניה כוללת עם סך הרבדים שבאדם.</p>",
      "<p>תפישה זו מוצאת ביטויה כבר אצל המשורר הרומי יובנאליס בשנת 100 לספירה, כמובעת בהיגד השגור, 'נֶׁפֶׁש ברִּיאָּה בגוּף בָּרִּיא'; והרחי בזאת הרב יצחק הכהן קוק בספרו 'אורות התשובה' ובמקורות נוספים: \"נשמה בריאה בגוף בריא מוכרחת היא לבוא לידי האושר הגדול של תשובה\" ('אורות התשובה', פרק ה, א). הגם שמוגשת קדימות תחבירית לבריאות הנפש או הנשמה כתלויה בבריאות הגוף, אין עוררין בדבר יחסי הגומלין והתלות בין המרכיבים. אף הרמב\"ם (המאה ה־ 12), שהיה כידוע גם רופא, אחז בתפישה הוליסטית הרואה בגוף ובנפש מהות אחת. מיוחס לו ביאור הערך 'בריאות' על־דרך האקרוסטיכון: \"בולם רוגזו יפחית אוכלו ויגביר תנועתו\" (הרמב\"ם מעלה נושא זה בספרו 'הלכות דעות', פרק ד).</p>",
      "<p>מובן כי מתוך התבוננות רחבה עלינו לקחת בחשבון את יתר המרכיבים המשפיעים על האדם, ומאפשרים לו להגיע לרמת כושר משמעותית ואיכותית, כגון: תזונה, שינה ועוד. הגם שלא מעניינו של ספר זה לתארם, הרי שאין להקל ראש בחשיבותם, כחלק מהתורה ההוליסטית.</p>",
      "<p>תפישת הכושר ההוליסטי מציעה אפוא להתבונן באפשרות להניע אדם לפעילות גופנית דרך מגוון כוחות, לא רק דרך הספקטרום הפיזי. כוחות אלה מכילים בתוכם מספר מועצם של רכיבי השפעה עבור כל אחד מן האספקטים של חיינו, כאמור החברתי, הרגשי, השכלי, הסביבתי ועוד.</p>",
      "<p>שכלול הגישה ההוליסטית הביאני לכדי פיתוח 'ארגז כוחות' שהם כ'כלי הפעלה' העומדים לרשות המאמן והמתאמן גם יחד, בהתאם לאישיותו ולנטיותיו של כל אחד ואחד מהם. כוחות אלה נמצאו בעיני כיעילים וכאפקטיביים ביותר, מעבר לכלֵׁי האימון השגורים, כמתווה לאורח חיים פעיל ובריא, המיוסד על המהות ההוליסטית. הם מהווים אמצעי הנעה לפעילות, אמצעי לשיפור מרכיבי הכושר בעת הפעילות, ואמצעי יעיל להתמדה ממושכת בה מתוך שלמות והנאה.</p>",
      "<p>ספר זה נועד לשרת קהילת מאַמנים, כמובן מתאמנים, מורים לחינוך גופני, חוקרים בתחום הספורט, מורים, וכל העוסקים בתחום הלכה ומעשה. תקוותי כי ימשוך קהילות רבות יותר אל התחום המאתגר של עולם הכושר ויסייע לפעול בו באופן מושכל ומהנה, כמשחק מענג.</p>",
    ],
  },
  {
    id: 1,
    name: "המדריך השלם לפעילות בחדר הכושר",
    shortDesc:
      'הספר נבחר על ידי מחלקת כושר קרבי בצה"ל כספר הראוי ביותר לשימוש קציני האימון גופני בחילות צה"ל השונים. הספר נבחר ע"י מחלקת הספורט של ע.ת.א כספר הראוי ביותר לשימוש רכזי הספורט. הספר נבחר כספר שי למצטיינים בחינוך במכללת סמינר הקיבוצים ועוד.',
    coverSrc: require("../assets/client-images/Books/Front-Covers/complete-guide.png"),
    fullCover: require("../assets/client-images/Books/Full-Covers/complete-guide.jpg"),
    purchaseMethod: "contact",
    purchaseMessage:
    '<h3>לרכישה צרו קשר <br /> </h3> <p style="margin-top= 0rem; margin-bottom= 0.5rem; font-size=1.3rem; font-weight=400">yigalpinchas@gmail.com</p>',
    language: "he",
    paragraphs: [
      "<h4>מהדורה 3 ומורחבת, 2019</h4>",
      '<p>הספר היחידי שתורגם מחבר ישראלי לאנגלית בתחום של החינוך הגופני ומשמש כ"טקסטבוק" בצפון אמריקה (הגרסה הישנה שתורגמה לאנגלית נמכרת ב- 45$, הגרסה העברית השלישית והמורחבת מכילה תוספת של כ-60 עמודים ע"י עם תוספות של איורים ועדכונים)</p>',
      "<p>הספר תורגם ופותח ל - DVD באמצעות University of Calgary שבקנדה: The Complete Multimedia Guide to Working Out in the Gym</p>",
      "<p>כאשר אנו עוסקים במונח 'כושר' כוונתנו ליכולת, כלומר לכשירות לבצע פעולה. שם העצם הזכרי כֹּשֶׁר שאול מתכונתו של הַכָּשֵׁר, זה שמצבו טוב. בעולם ההלכה הכַשרוּת היא תַקִּינות. המילה כוללת גם משמעות של יכולת ואפשרות, כגון בצירופים: כושר עבודה, כושר ניתוח. כך אפוא כושר גופני.</p>",
      "<p>הספר מכיל קונספציה ורציונל בכל פרק ומאפשר חשיבה רפלקטיבית במהלך ואחרי הפעילות. הספר מחנך לפעילות גופנית מאוזנת ומושכלת וכתוב בראייה הוליסטית.</p>",
      "<p>בספר כ- 300 איורים המדויקים ביותר בשחור לבן בשווי 25,000$</p>",
      "<p>הספר היינו משנה סדורה בתחום הפעילות הגופנית. תורת הכושר הגופני, מושגי יסוד, שיטות אימון לפיתוח כוח, סבולת וגמישות, ומשמש כספר לימוד בקורסי מאמנים: אתלטיקה, אירובי, חדרי כושר ועוד</p>",
      "<p>בספר פרקים מעשיים ייחודיים המיטיבים להמחיש את אופן הביצוע של תרגילי הכוח והאירובי השונים. (הרציונל לביצוע התרגילים מוסבר באופן שווה לכל קורא), פרקים הסוקרים באופן רפלקטיבי את המכשור לפעילות אירובית ופעילות אנאירובית, ופרק בנושא תזונה המאפשר הכרה והבנה לכל מרכיבי אבות המזון, טיפים בנושא תזונה לפעילות אירובית ותזונה לפעילות אנאירובית והנחיות לתזונה נבונה</p>",
      '<p>בסוף ספר פרק בנושא איך לבנות תוכניות אימון אישיות (ילדים, מבוגרים, צרכים ייחודיים), המאפשר באמצעות מתן תשובות לשאלונים ייחודיים (גרסה ייחודית ע"ס מחקרים וניסיון) לזהות את ההבדל בין רצון לצורך ואיך להפוך את הצרכים למטרות</p>',
      '<p>הספר נבחר על ידי מחלקת כושר קרבי בצה"ל כספר הראוי ביותר לשימוש קציני האימון גופני בחילות צה"ל השונים. הספר נבחר ע"י מחלקת הספורט של ע.ת.א כספר הראוי ביותר לשימוש רכזי הספורט. הספר נבחר כספר שי למצטיינים בחינוך במכללת סמינר הקיבוצים ועוד</p>',
    ],
    Link: [
      {
        src: "http://www.amazon.com/Complete-Holistic-Guide-Working-Out/dp/155238215X",
        altText: "לינק לרכישה",
      },
    ],
  },
  {
    id: 2,
    name: "ממתיחות לגמישות - המדריך השלם",
    shortDesc:
      "הספר הנוכחי מתמקד בדרכים לשיפור הגמישות הפיזית מתוך הנחה, שזו רק שלב התחלתי למימוש כלל ההיבטים של הגמישות; שריר גמיש הוא שריר סתגלן, כשם שניתן להגדיל את היקף התנועה כך ניתן להרחיב את גבולות החשיבה והדימיון.",
    coverSrc: require("../assets/client-images/Books/Front-Covers/stretching.webp"),
    fullCover: require("../assets/client-images/Books/Full-Covers/stretching.jpg"),
    purchaseMethod: "contact",
    purchaseMessage:
    '<h3>לרכישה צרו קשר <br /> </h3> <p style="margin-top= 0rem; margin-bottom= 0.5rem; font-size=1.3rem; font-weight=400">yigalpinchas@gmail.com</p>',
    language: "he",
    paragraphs: [
      "<h4>פתח דבר</h4><p>מרכיב הגמישות ניתן לפיתוח בכל גיל והוא איננו מוגבל למין או לאוכלוסייה מסוימת, כל אחד יכול לשפר את מרכיב הגמישות בהיבטיו השונים:</p>",
      "<p>- בהיבט המחשבתי מציין את מידת היכולת להשתנות</p>",
      "<p>- בהיבט החברתי מציין את מידת היכולת לקבל/להכיל את הזר והשונה</p>",
      "<p>- בהיבט הריגשי מציין את מידת היכולת להתמודד עם דחיית סיפוקים והתמודדות עם תחושות של אי נוחות</p>",
      "<p>לסיכום ניתן לומר שהגמישות בכללותה היא זו שמאפשרת לנו להסתגל לחיים, להתמודד עם אתגרי החיים החל מרמת האי נוחות/סבל ועד להגשמת הסיפוק המירבי.</p>",
      "<h4>הספר הנוכחי מתמקד בדרכים לשיפור הגמישות הפיזית מתוך הנחה, שזו רק שלב התחלתי למימוש כלל ההיבטים של הגמישות; שריר גמיש הוא שריר סתגלן, כשם שניתן להגדיל את היקף התנועה כך ניתן להרחיב את גבולות החשיבה והדימיון</h4><p></p>",
    ],
  },
  {
    id: 3,
    name: "Holistic Fitness Training",
    shortDesc:
      "After decades of working in physical education and teaching physical education to  thousands of trainees and students in both formal and informal systems, I realized that  achieving success requires a different approach from those currently prevalent in the  field. Throughout teaching physical activity - whether in gyms, classes, schools, high  schools, or colleges - I discovered that existing approaches do not provide an adequate  response for diverse populations.",
    coverSrc: require("../assets/client-images/Books/Front-Covers/Holistic-fitness-en.png"),
    fullCover: require("../assets/client-images/Books/Full-Covers/Holistic-fitness-en.png"),
    purchaseMethod: "url",
    purchaseMessage:
      "https://www.goodreads.com/book/show/216373664-holistic-fitness-training",
    language: "en",
    paragraphs: [
      "<h4>Holistic Fitness training ® - Background and Introduction</h4>",
      "<p>After decades of working in physical education and teaching physical education to  thousands of trainees and students in both formal and informal systems, I realized that  achieving success requires a different approach from those currently prevalent in the  field. Throughout teaching physical activity - whether in gyms, classes, schools, high  schools, or colleges - I discovered that existing approaches do not provide an adequate  response for diverse populations. It's a known fact that despite the obvious and clear  advantages of physical training, most people still avoid fitness activities, and those who  do participate struggle to maintain consistent practice. </p>",
      "<p>Over the years, I developed a new approach designed to cater to all trainees, especially  those thousands who until now have dropped out of fitness programs, failed to maintain  them, or avoided them altogether. This approach, with its powers and strengths, was  specifically founded to address the trainee's needs across multiple aspects: physical,  emotional, spiritual, and mental. </p>",
      "<p><b>The Holistic Fitness® approach</b> is based on holistic philosophy, which views humans  as a complete whole, a single 'organic' system composed of body, soul, spirit, and  mind. When one component changes or its function changes, all other components in  the system are affected and change accordingly, as there is a clear mutual relationship  and interconnection between all parts of the whole. Given this, to create physical  change in a trainee, we must observe all their facets, including psychological,  behavioural, social, etc. We must also identify the relationships between the various  interconnected and mutually influencing aspects. </p>",
      "<p>When we deal with the term 'fitness,' we refer to capability, meaning the ability to  perform an action. The word also includes the meaning of ability and possibility, as in  combinations like: work capacity, analytical ability. Thus, physical fitness.</p>",
      "<p>Therefore, 'physical fitness' in its broad sense is just one of the many human capacities  alongside mental, emotional, social, and other capacities. In this context, 'physical  fitness' is what enables a person to operate all body systems across the full range of  functional activity to ensure existence and meet needs. This definition includes various  body systems: nervous, respiratory, cardiovascular, movement, digestive, urinary,  reproductive, and immune systems. However, the term 'physical fitness' refers only to  the movement system at a significant effort level! </p>",
      "<p>While 'physical fitness' refers to the body alone, and specifically to the movement  system alone at a significant effort level, the 'Holistic Fitness' approach addresses each  human dimension: physical, mental, spiritual, and cognitive across all effort levels. This  is therefore a perception that advocates cultivating a healthy and active lifestyle based  on physical activity that strives for overall harmony with all human layers. </p>",
      "<p>This perception was already expressed by the Roman poet Juvenal in 100 CE, as stated  in the common phrase, 'A healthy soul in a healthy body'; and Rabbi Abraham Isaac  Kook expanded on this in his book 'Orot HaTeshuva' and other sources: \"A healthy soul  in a healthy body must come to the great happiness of repentance\" ('Orot HaTeshuva',  Chapter 5, 1). Although syntactic priority is given to mental or soul health as dependent  on physical health, there is no dispute about the interrelationship and dependence  between the components.  </p>",
      "<p>It is clear that from a broader perspective, we must take into account other components  that affect humans and enable them to reach a significant and quality fitness level,  such as: nutrition, sleep, and more. Although it is not the purpose of this book to describe them, their importance should not be underestimated as part of the holistic  doctrine. </p>",
      "<p>The Holistic Fitness concept therefore proposes to consider the possibility of motivating  a person to physical activity through various powers, not just through the physical  spectrum. These powers contain an enhanced number of influential components for  each aspect of our lives, including social, emotional, intellectual, environmental, and  more. </p>",
      "<p>The refinement of the holistic approach led me to develop a 'power toolbox' that serves  as 'operating tools' available to both trainer and trainee, according to each person's  personality and inclinations. I found these powers to be most efficient and effective,  beyond conventional training tools, as a blueprint for an active and healthy lifestyle  founded on holistic essence. They constitute a means of motivation for activity, a  means to improve fitness components during activity, and an effective means for long term persistence with completeness and enjoyment. This book is intended to serve the community of trainers, trainees, physical education  teachers, sports researchers, teachers, and all those involved in the field in theory and  practice. I hope it will attract more communities to the challenging field of fitness and  help them operate in it intelligently and enjoyably, as a pleasing game. </p>",
    ],
  },
  {
    id: 4,
    name: "Start With Stretching",
    shortDesc:
      "The current book focuses on ways to improve physical flexibility based on the  assumption that this is just an initial stage for realizing all aspects of flexibility; a flexible  muscle is an adaptable muscle, just as one can increase range of motion, so can one  expand the boundaries of thought and imagination",
    coverSrc: require("../assets/client-images/Books/Front-Covers/start-stretching-en.png"),
    fullCover: require("../assets/client-images/Books/Full-Covers/start-stretching-en.png"),
    purchaseMethod: "url",
    purchaseMessage:
      "https://www.goodreads.com/book/show/216388426-start-with-stretching",
    language: "en",
    paragraphs: [
      "<h4>Flexibility is key to adaptation: A theoretical and visual guide to increased flexibility  and well-being.</h4> ",
      "<p>The flexibility component can be developed at any age and is not limited to a specific  gender or population. Anyone can improve their flexibility component in its various  aspects: </p>",
      "<p><b>-In the cognitive aspect</b>, it indicates the degree of ability to change </p>",
      "<p><b>- In the social aspect</b>, it indicates the degree of ability to accept/contain the foreign  and different </p>",
      "<p><b>- In the emotional aspect</b>, it indicates the degree of ability to cope with delayed  gratification and deal with feelings of discomfort </p>",
      "<p>In other words, it can be said that overall flexibility is what allows us to adapt to life, to  cope with life's challenges ranging from discomfort/suffering to achieving maximum  satisfaction. </p>",
      "<p>The current book focuses on ways to improve physical flexibility based on the  assumption that this is just an initial stage for realizing all aspects of flexibility; a flexible  muscle is an adaptable muscle, just as one can increase range of motion, so can one  expand the boundaries of thought and imagination. </p>",
      "<p>Dreaming of a stronger, more flexible body? This book is the perfect guide for you! You'll  find a variety of detailed exercises and clear illustrations that will help you safely and  effectively develop your flexibility. With no need for special equipment or prior  knowledge, you can start training today and enjoy the many benefits of flexibility:  improved performance, injury prevention, stress relief, and better posture. </p>",
      "<h4>Why is this book for you? <h4>",
      "<ul><li><b>Simple and clear:</b> Detailed explanations and high-quality images to help you  understand and correctly perform the exercises. </li><li><b>Diverse: </b>A wide range of exercises to suit any level and any goal. </li><li><b>Effective:</b> Focused exercises that will help you achieve results quickly.</li> <li><b>Safe:</b> Detailed safety guidelines to ensure proper and safe training. </li></ul>",
      '<h4 style="color: #da6739; font-size: 1.2rem"; > Start your journey to stronger, more flexible body today!</h4>',
    ],
  },
  {
    id: 5,
    name: "BE YOUR OWN PERSONAL TRAINER",
    shortDesc:
      'Backed by years of experience and scientific evidence, expert fitness trainer and veteran physical educator Yigal Pinchas, Ph.D., presents the most \n comprehensive and detailed guide to training in the gym available to date. Recognized by the IDF as the leading training and instruction manual for \n soldiers, "Be Your Own Personal Trainer" has received recommendations from leading fitness organizations in the world since its initial publication.',
    coverSrc: require("../assets/client-images/Books/Front-Covers/personal-trainer.webp"),
    fullCover: require("../assets/client-images/Books/Full-Covers/Personal-trainer.png"),
    purchaseMethod: "url",
    purchaseMessage:
      "https://www.goodreads.com/book/show/160857448-be-your-own-personal-trainer",
    language: "en",
    paragraphs: [
      "<h4>Everything you need to achieve maximum gains is in the palm of your hand</h4>",
      '<h4>DESCRIPTION</h4><p>Backed by years of experience and scientific evidence, expert fitness trainer and veteran physical educator Yigal Pinchas, Ph.D., presents the most comprehensive and detailed guide to training in the gym available to date. Recognized by the IDF as the leading training and instruction manual for soldiers, "Be Your Own Personal Trainer" has received recommendations from leading fitness organizations in the world since its initial publication</p>',
      "<h4>Everything you need to achieve maximum gains is in the palm of your hand</h4><p>People exercise for a variety of different reasons. Whether you are looking to lose weight, tone your muscles, improve your mobility, or gain an edge on the field, your individual goals should determine your training program, not the other way around. A well-informed training regimen and comprehensive holistic approach is the best way to achieve these goals while minimizing the risk of injury or failure. And you can make one yourself</p>",
      "<h4>FROM THE AUTHOR</h4><p>This book was born out of necessity, specifically, the need to create order and uniformity in fitness terminology that will allow trainers and trainees to have fruitful, more effective sessions. I did my best to present a unilateral interpretation of the common terms and practices in the field, with as wide a context as possible. All the while giving readers a deep and meaningful understanding of how they interact with one another, and how to practice both effectively and safely.</p>",
      '<p>I put in "Be Your Own Personal" Trainer several unique chapters, including one detailing the importance of flexibility not only in sports performance but in our daily lives in general. Nutrition, muscle development, stamina, and goals management all take part in this theoretical and practical guide</p>',
      "<p>Whether you are looking to lose weight, tone your muscles, improve your mobility, or gain an edge on the field, your individual goals should determine your training program, not the other way around. A well-informed training regimen and comprehensive holistic approach is the best way to achieve these goa-ls while minimizing the risk of injury or failure. And you can make one yourself</p>",
      "<p>The complete guide to Working out in the gym with a holistic approach is intended for exercisers in general and sports teachers and fitness trainers in particular. The book is written in a structured way, which allows the reader to progress step by step, starting from his first steps in the gym to the stages of a professional and independent exerciser</p>",
      "<p></p>",
      "<p>Revised and optimized with the latest research in physical gym training, expert fitness trainer and veteran physical educator Yigal Pinchas, Ph.D., presents the most comprehensive and detailed guide to training in the gym available to date. this edition includes:</p>",
    ],
    points: [
      "100+ specially designed exercises that target different muscle groups and cover various aspects of training, including stretches, cardio, aerobics, and effectively using multi-purpose machines",
      "A scientifically supported personally tailored training program designed specifically for you",
      "Over 300 fully illustrated original drawings that provide clear and precise instructions for optimal range of movement and technique",
      "Comprehensive instructions for building strength and stamina, improving flexibility, promoting muscle relaxation, and maintaining a balanced training and diet",
      "The “Five by Five” model: how integrated fitness components work, and their contribution to the development of sports skills",
      "New proven methods for achieving training commitments and goals while minimizing the risk of injuries",
    ],
  },
  {
    id: 6,
    name: "The Complete Holistic Guide To Working Out In The Gym",
    shortDesc:
      "Training in the gym is one of the most common sports in the Western world. Thanks to the \nenormous scientific development in the industry, the gym stands out as the most effective means of \n managing the body. The gym provides a wide range of physical, health, mental and social needs and \n is suitable for diverse target populations.",
    coverSrc: require("../assets/client-images/Books/Front-Covers/complete-holistic-guide.png"),
    fullCover: require("../assets/client-images/Books/Front-Covers/complete-holistic-guide.png"),
    purchaseMethod: "unavailable",
    purchaseMessage: "Sorry, this item is not longer available",
    language: "en",
    paragraphs: [
      "<p>Training in the gym is one of the most common sports in the Western world. Thanks to the enormous scientific development in the industry, the gym stands out as the most effective means of managing the body. The gym provides a wide range of physical, health, mental and social needs and is suitable for diverse target populations</p>",
      "<p>In this book, the most up-to-date research aspects on the subject are concentrated, starting from the basic concepts, through the description of the execution of the exercises, to the construction of a personal training plan and guidelines for a sensible diet. The book contains about 200 unique illustrations that help describe and illustrate how to perform the exercises in a visual way that makes the book a useful guide</p>",
      "<p>The complete guide to Working out in the gym with a holistic approach is intended for exercisers in general and sports teachers and fitness trainers in particular. The book is written in a structured way, which allows the reader to progress step by step, starting from his first steps in the gym to the stages of a professional and independent exerciser</p>",
      "<p></p>",
      "<p>Through this book you will learn:</p>",
    ],
    points: [
      "The way of performing the exercises in a visual way",
      "The advanced training methods for developing the various fitness components",
      "The pros and cons of the gym",
      "How to increase awareness and motivation for physical activity",
      "How to organize your body and train intelligently",
      "A variety of exercises for all muscle groups in the body",
      "How to realize the training goals without getting injured",
      "How to build a personal training plan for each trainee according to his needs",
      "The nutritional components required for the various types of activity",
      "A balanced approach to training and eating habits",
      "Reflective and balanced thinking during and after the activit",
    ],
  },
  {
    name: "ספרים באנגלית",
    shortDesc:
      "כל הספרים קיימים בתרגום מקצועי לאנגלית. לפרטים נוספים על הספרים ורכישתם, לחצו כפתור המעבר לספרים באנגלית",
    language: "he",
  },
];
